import React, { useRef, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box'
import * as NEA from 'fp-ts/NonEmptyArray'
import * as A from 'fp-ts/Array'
import Grid from '@mui/material/Grid'
import {pipe} from 'fp-ts/function'
import { row, rowkey } from './types';
import { Typography } from '@mui/material';


const colormapping = {
  'blank': 'white',
  'incorrect': 'lightgray',
  'wrongloc': 'yellow',
  'correct': 'lightgreen'
}

const rotate = (current:string) => 
  current === 'incorrect' ? 'wrongloc':
    current === 'wrongloc' ? 'correct' :
      current === 'correct' ? 'incorrect' : 'blank'

const Cell = (props: {rows:row[],
                      row:row,
                      num:rowkey,
                      setRow: (r:row) => void,
                      text: React.MutableRefObject<null>,
                      next: React.MutableRefObject<null>}) => 
  <TextField id="outlined-basic" label="" variant="outlined" size='medium'
    inputProps={{
      style: { textAlign: 'center',
               fontWeight: 'bolder',
               fontSize: 30,
               backgroundColor: colormapping[props.row[props.num].type] },
      maxLength: 1,
      
    }}
    inputRef={props.text}
    value={props.row[props.num].val}
    onClick={() => {
                      props.setRow({...props.row,
                                 [props.num]: {...props.row[props.num],
                                 type:rotate(props.row[props.num].type)}})
                    }}
    onChange={(e) => {

      if(e.target.value.match(/[a-z]/i) || e.target.value.length === 0){
        props.setRow({...props.row,
          [props.num]: {...props.row[props.num],
          val: e.target.value === '' ? '' : e.target.value.toUpperCase().substring(0, 1),
          type: e.target.value === '' ? 'blank' : 'incorrect' } 
        })
        ;
        if(props.next && props.next.current && e.target.value !== ''){
          (props.next.current as any).focus()
        }
      }
    }}
  />

const cellrow = (rows:row[], row:row, setRow: (r:row) => void, texts: React.MutableRefObject<null>[]) =>
  A.map((i:number) => <Cell rows={rows} row={row} num={i as unknown as rowkey} setRow={setRow} text={texts[i]} next={texts[i+1]}></Cell>)

const defaultrowvals:row = {0:{val:'', type:'blank'}, 1: {val:'', type:'blank'}, 2: {val:'', type:'blank'}, 3:{val:'', type:'blank'}, 4:{val:'', type:'blank'}}



const SolutionList = (props: {solutions: string[]}) =>
  <Grid container spacing={1} alignItems="center" columns={{xs: 6}}>
    {pipe(props.solutions, A.map(w => <Grid container item justifyContent='center' xs={1}>{w}</Grid>))}
  </Grid>

const MSolutionList = React.memo(SolutionList)


const worker = new Worker(new URL('./solver.worker.ts', import.meta.url));

const Loading = 
  <Grid container alignItems="center" justifyContent="center">
    <Grid item>Loading...</Grid>
  </Grid>

let loadCount = 0

const App = () => {

  const [row1, setRow1] = useState({...defaultrowvals});
  const [row2, setRow2] = useState({...defaultrowvals});
  const [row3, setRow3] = useState({...defaultrowvals});
  const [row4, setRow4] = useState({...defaultrowvals});
  const [row5, setRow5] = useState({...defaultrowvals});
  const r1texts = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
  const r2texts = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
  const r3texts = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
  const r4texts = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
  const r5texts = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]

  const rows = [row1, row2, row3, row4, row5]

  const r1 = pipe(NEA.range(0,4), cellrow(rows, row1, setRow1, r1texts))
  const r2 = pipe(NEA.range(0,4), cellrow(rows, row2, setRow2, r2texts))
  const r3 = pipe(NEA.range(0,4), cellrow(rows, row3, setRow3, r3texts))
  const r4 = pipe(NEA.range(0,4), cellrow(rows, row4, setRow4, r4texts))
  const r5 = pipe(NEA.range(0,4), cellrow(rows, row5, setRow5, r5texts))

  const [solutionDisplay, setSolutionDisplay] = useState(Loading)

  worker.onmessage = ({ data: { answer } }) => {
    loadCount--
    if(loadCount === 0){
      setSolutionDisplay(<MSolutionList solutions={answer}/>)
    }
  };

  useEffect(() => {
    loadCount++
    worker.postMessage({rows})
    setSolutionDisplay(Loading)
 }, [row1, row2, row3, row4, row5]);

  return <React.Fragment>
            <Grid container alignItems="center" justifyContent="center" columns={{xs:1}}>
              <Grid container item justifyContent='center' xs={1}><Typography padding="5"><h1>Wordle Solver</h1></Typography></Grid>
              <Grid container item justifyContent='center' alignItems="center" xs={1}><Typography alignItems="center" justifyContent="center" padding="5">Enter the letters that you've played already, then click the boxes to switch between grey, yellow and green</Typography></Grid>
              <Grid container item xs={1}><br/></Grid>
            </Grid>

            <Box sx={{'& .MuiTextField-root': { width: '60px' }}}>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item >{ r1 }</Grid>
              </Grid>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item >{ r2 }</Grid>
              </Grid>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item >{ r3 }</Grid>
              </Grid>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item >{ r4 }</Grid>
              </Grid>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item >{ r5 }</Grid>
              </Grid>
          </Box>
          <br/>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item>Possible solutions</Grid>
          </Grid>
          <br/>
          {solutionDisplay}
        </React.Fragment>
}


export default App;
